<template>
  <div class="radio-main">
    <div ref="musicContainer" class="music-container" id="music-container">
      <div class="music-info">
        <h4 dir="rtl" id="title">
            {{title}}
        </h4>
        <div ref="progressContainer" class="progress-container" id="progress-container">
          <div ref="progress" class="progress" id="progress"></div>
        </div>
      </div>
      
       <audio ref="audio" id="audio"></audio>

        <YouTube 
            :src="youtube_src" 
            ref="youtube" 
            @state-change="handle_youtube_state_change"
            v-show="false"
        />

      <div class="img-container">
        <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fsummer_375x375.jpg?alt=media&token=1b380b93-82dc-4c04-b309-2403130fe944" ref="cover" alt="music-cover" id="cover" />
      </div>
      <div class="navigation">
        <button @click="prevSong" id="prev" class="action-btn">
          <i class="fas fa-backward"></i>
        </button>
        <button @click="handle_play_btn" ref="playBtn" id="play" class="action-btn action-btn-big">
          <i class="fas fa-play"></i>
        </button>
        <button @click="nextSong" id="next" class="action-btn">
          <i class="fas fa-forward"></i>
        </button>
      </div>
    </div>
    <div class="search-bar">
        <el-input v-model="search" placeholder="חיפוש שיר" />
    </div>
    <div class="songs-list-header">
        <h1>Playlist</h1>
    </div>
    
    <div class="songs-list-content">
        <template v-for="(song) in filter_by_search" :key="song.song_url">
            <div :data-song="selected_song && song.song_url==selected_song.song_url?'selected':'not-selected'" class="songs-list-content-song">
                <i class="material-icons music-icon">music_note</i>
                <p>{{song.song_name}}</p>
                <i @click="handle_pause_selected()" v-if="selected_song && song.song_url==selected_song.song_url" class="material-icons play-icon">pause</i>
                <i @click="handle_play_selected(song)" v-else class="material-icons play-icon">play_arrow</i>
            </div>
        </template>   
    </div>
    
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { computed, onBeforeUnmount, onMounted, watchEffect} from '@vue/runtime-core';
import {get_songs_from_db} from './Admin/scripts/script'
import store from '../../../store';
import { projectFirestore } from '../../../firebase/config';
import YouTube from 'vue3-youtube'
import _ from 'lodash';

export default {
    components:{YouTube},
    setup(){

       
        const youtube_src = ref('')

        const youtube = ref()

        const onReady = ()=>{
            youtube.value.playVideo()
        }

        const promotion_song = ref(null)
        const selected_song = ref(null)
        const search = ref('')
        const musicContainer = ref()
        const playBtn = ref()
        const audio = ref()
        const cover = ref()
        const title = ref()
        const progress = ref()
        const progressContainer = ref()

        //for listeners
        let unsub_interval
        let unsub_snapshot

        //keep track of song
        const songIndex = ref(0);

        //Song titles
        const songs = ref([]);

        const filter_by_search = ref(computed(()=>{
            if(search.value == '') return songs.value
            return songs.value.filter(song=>{
                if(song.song_name.toLowerCase().includes(search.value.toLowerCase())) return song
            })
        }))

        
        const handle_youtube_state_change = (e)=>{
            if(e.data==0) nextSong()
        }
        const handle_pause_selected = () => {
            selected_song.value = null
            pauseSong()
        }

        const handle_play_selected = (song) => {
            youtube.value.stopVideo()
            audio.value.pause();
            selected_song.value = song
            songIndex.value = song.index
            loadSong(song)
            playSong()
        }

        //Update song details
        function loadSong(song) {
            title.value = song.song_name;
            if(!song.song_url.includes('youtube.com')){
                audio.value.src = song.song_url;
                // cover.value.src = `images/${song}.jpg`;
            }
        }

        //Pause song
        function pauseSong() {
            if(selected_song.value) selected_song.value = null
            musicContainer.value.classList.remove("play");
            playBtn.value.querySelector("i.fas").classList.add("fa-play");
            playBtn.value.querySelector("i.fas").classList.remove("fa-pause");
            if(songs.value[songIndex.value].song_url.includes('youtube.com')){
                youtube.value.pauseVideo()
            }else{
                audio.value.pause();
            }
        }

        //Play song
        function playSong(propmotion_song) {
            progressContainer.value.style.display = 'block'
            if(!selected_song.value) selected_song.value = songs.value[songIndex.value]
            musicContainer.value.classList.add("play");
            playBtn.value.querySelector("i.fas").classList.remove("fa-play");
            playBtn.value.querySelector("i.fas").classList.add("fa-pause"); 
            
            if(propmotion_song && !propmotion_song.song_url.includes('youtube.com')){
                audio.value.play();
            }
            else if(propmotion_song && propmotion_song.song_url.includes('youtube.com')){
                progressContainer.value.style.display = 'none'
                youtube_src.value = propmotion_song.song_url
                youtube.value.playVideo()
            }
            else if(songs.value[songIndex.value].song_url.includes('youtube.com')){
                progressContainer.value.style.display = 'none'
                youtube_src.value = songs.value[songIndex.value].song_url
                youtube.value.playVideo()
            }else{
                audio.value.play();
            }
        }


        const handle_play_btn = ()=>{
            const isplaying = musicContainer.value.classList.contains("play");
            if (isplaying) {
                pauseSong();
            } else {
                playSong();
            }
        }


        // Previous song
        function prevSong() { 
            youtube.value.stopVideo()
            audio.value.pause()
            if (songIndex.value == 0) {
                songIndex.value = songs.value.length - 1;
            }else{
                songIndex.value-=1
            }
            selected_song.value = songs.value[songIndex.value]
            loadSong(songs.value[songIndex.value]);
            playSong();
        }

        //Next song
        function nextSong() {
            youtube.value.stopVideo()
            audio.value.pause() 
            if (songIndex.value == songs.value.length - 1) {
                songIndex.value = 0;
            }else{
                songIndex.value+=1;
            }
            selected_song.value = songs.value[songIndex.value]
            loadSong(songs.value[songIndex.value]);
            playSong();
        }


        //Update progress bar
        function updateProgress(e) {
            const { duration, currentTime } = e.srcElement;
            const progressPrecent = (currentTime / duration) * 100;
            progress.value.style.width = `${progressPrecent}%`;
        }

        //Set progress bar
        function setProgress(e) {
            const width = this.clientWidth;
            const clickX = e.offsetX;
            const duration = audio.value.duration;
            audio.value.currentTime = (clickX / width) * duration;
        }

        const listen_to_promotion = ()=>{
            unsub_snapshot = projectFirestore.collection('Applications').doc('zMmmIKFq2BOQEWwnZVVM').collection('Promotions')
            .onSnapshot(snapshot => {
                snapshot.docChanges().forEach((change) => {
                    promotion_song.value = change.doc.data()
                })
            })
        }

 
        const init = async()=>{
            store.state.loader = true
            let res = await get_songs_from_db()
            res = _.shuffle(res)
            for(let i=0; i<res.length; i++){
                res[i].index = i
            }
            songs.value = res
            store.state.loader = false
            if(songs.value.length>0){
                loadSong(songs.value[songIndex.value]);
            }
        }

        onMounted(async()=>{
            await init()

            //Time/song update
            audio.value.addEventListener("timeupdate", updateProgress);

            //Song ends
            audio.value.addEventListener("ended", nextSong);

            //Click on progress bar
            progressContainer.value.addEventListener("click", setProgress);

            listen_to_promotion()

            
        })

        watchEffect(()=>{
            if(promotion_song.value){
                if(unsub_interval) clearInterval(unsub_interval)
                unsub_interval = setInterval(()=>{
                    youtube.value.stopVideo()
                    audio.value.pause();
                    loadSong(promotion_song.value)
                    playSong(promotion_song.value)
                },promotion_song.value.promotion_interval * 60 * 1000)
            }
        })

        const stop_listeners = () => {
            //Time/song update
            audio.value.removeEventListener("timeupdate", updateProgress);

            //Song ends
            audio.value.removeEventListener("ended", nextSong);

            //Click on progress bar
            progressContainer.value.removeEventListener("click", setProgress);
            if(unsub_interval){
                clearInterval(unsub_interval)
            }
            if(unsub_snapshot){
                unsub_snapshot()
            }
        }

        onBeforeUnmount(()=>{
            stop_listeners()
        })

       


        return{
            handle_play_btn,
            prevSong,
            nextSong,
            setProgress,
            playSong,
            handle_pause_selected,
            musicContainer,
            playBtn,
            audio,
            cover,
            progress,
            title,
            songIndex,
            songs,
            progressContainer,
            selected_song,
            handle_play_selected,
            filter_by_search,
            search,
            youtube,
            onReady,
            youtube_src,
            handle_youtube_state_change
        }
    }
}
</script>

<style scoped>
    .radio-main *{
        direction: ltr;
    }

    .radio-main h1,.radio-main h4{
        color: #fff;
    }

    .radio-main{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 5px;
    }

    .music-container {
        position: relative;
        background-color: #fff;
        border-radius: 15px;
        display: flex;
        padding: 20px 30px;
        margin: 100px 0 0 0;
        z-index: 1;
        width: 100%;
        max-width: 500px;
        height: 100px;
    }

    .img-container {
        position: relative;
        width: 110px;
    }

    .img-container img {
        border-radius: 50%;
        object-fit: fill;
        height: 110px;
        width: inherit;
        position: absolute;
        bottom: 0;
        left: -15px;
        animation: rotate 3s linear infinite;
        animation-play-state: paused;
        z-index: 100;
    }

    .music-container.play .img-container img {
        animation-play-state: running;
    }

    @keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
    }
    .navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
    .action-btn {
    background-color: #fff;
    border: 0;
    color: #dfdbdf;
    font-size: 20px;
    cursor: pointer;
    padding: 10px;
    margin: 0 20px;
    }
    .action-btn.action-btn-big {
    color: #cdc2d0;
    font-size: 30px;
    }
    .action-btn:focus {
    outline: 0;
    }
    .music-info {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 15px 15px 0 0;
    position: absolute;
    top: 0;
    left: 20px;
    width: calc(100% - 40px);
    padding: 10px 10px 10px 150px;
    opacity: 0;
    transform: translateY(0%);
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
    z-index: 0;
    }
    .music-container.play .music-info {
        opacity: 1;
        transform: translateY(-100%);
    }
    .music-info h4 {
    margin: 0;
    }
    .progress-container {
        background: #fff;
        border-radius: 5px;
        cursor: pointer;
        margin: 10px;
        height: 4px;
        width: 100%;
    }
    .progress {
        background-color: #fe8daa;
        border-radius: 5px;
        height: 100%;
        width: 0%;
        transition: width 0.1s linear;
    }
    .search-bar{
        width: 100%;
        max-width: 500px;
        height: 50px;
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    .songs-list-header{
        width: 100%;
        max-width:500px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: var(--secondary);
        border-radius: 10px;
        margin-top: 10px;
    }
    
    .songs-list-content{
        width: 100%;
        max-width: 500px;
        height: calc(100% - 270px);
        overflow:hidden;
        overflow-y: auto;
        padding: 10px 0;
    }
    .songs-list-content-song{
        width: 100%;
        height: 30px;
        background: whitesmoke;
        margin-bottom: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .songs-list-content-song p{
        font-size: 18px;
        width: 90%;
    }
    .music-icon,.play-icon{
        font-size: 22px;
        color: var(--purple);
        width: 5%;
        border-radius: 50%;
        user-select: none;
    }
    .play-icon{
        cursor: pointer;
    }   
    [data-song="selected"]{
        background: var(--success);
        color: #fff;
    }
   
</style>